
import { defineComponent } from "vue-demi";
import Subscriber from "@/components/owner/subscriber/index.vue";

export default defineComponent({
  name: "OwnerSubscriberView",
  components: {
    Subscriber,
  },
});
